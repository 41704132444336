import React, { useState } from 'react'
import Img from 'gatsby-image'
import styled from 'styled-components'
import { rem, grid, value, position } from 'styled-tidy'
import stopEvent from 'lib/common/stop-event'
import media from 'lib/styles/media'
import theme from 'lib/styles/theme'
import Wrap from 'components/wrap'
import Modal from 'components/modal'
import Image from 'components/image'
import Badge from 'components/badge'
import SwipeEvents from 'components/swipe-events'

const { pearl, shadow } = theme.colors

export const GalleryWrap = styled(Wrap)`
  &&& {
    padding: ${rem(16)} 0;
    position: relative;

    ${media.small`
      padding: ${rem(16)};
    `}
  }
`

const GalleryGrid = styled.ul`
  ${grid(value('columns'), 4)}
  list-style: none;
  margin: ${rem(2)};
  width: 100%;

  li {
    position: relative;
  }

  ${media.small`
    grid-gap: ${rem(8)}
  `}

  ${media.medium`
    grid-gap: ${rem(16)}
  `}
`

const Item = styled.a`
  display: block;

  ${media.medium`
    border: ${rem(1)} solid ${pearl};
  `}
`

const ModalContent = styled.div`
  img {
    display: block;
    object-fit: contain;
    object-position: top;
    width: 100%;
  }

  ${media.medium`
    img {
      max-height: calc(100vh - ${rem(48)});
      width: auto;
    }
  `}
`

const NewBadge = styled(Badge)`
  ${position('absolute', 8, 8, 'auto', 'auto')}
  box-shadow: 0 ${rem(1)} ${rem(1)} ${rem(1)} ${shadow};
`

const Gallery = ({ imageNodes, columns = 3 }) => {
  const [showModal, setShowModal] = useState(false)
  const [selectedImage, setSelectedImage] = useState('')

  const handleImageClick = imagePath => event => {
    stopEvent(event)
    setSelectedImage(imagePath)
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const selectPreviousImage = () => {
    const selectedIndex = imageNodes.findIndex(
      ({ path }) => path === selectedImage,
    )
    const previousImage = imageNodes[selectedIndex - 1]

    if (previousImage) {
      setSelectedImage('')
      global.requestAnimationFrame(() => {
        setSelectedImage(previousImage.path)
      })
    } else {
      closeModal()
    }
  }

  const selectNextImage = () => {
    const selectedIndex = imageNodes.findIndex(
      ({ path }) => path === selectedImage,
    )
    const nextImage = imageNodes[selectedIndex + 1]

    if (nextImage) {
      setSelectedImage('')
      global.requestAnimationFrame(() => {
        setSelectedImage(nextImage.path)
      })
    } else {
      closeModal()
    }
  }

  return (
    <>
      <GalleryGrid columns={columns}>
        {imageNodes.map(({ path, fluid }) => (
          <li key={fluid.src}>
            <Item onClick={handleImageClick(path)}>
              <Img fluid={fluid} />
              {path.match(/gallery\/new\//i) && <NewBadge>New</NewBadge>}
            </Item>
          </li>
        ))}
      </GalleryGrid>
      <Modal
        title="Hello"
        show={showModal}
        onClose={closeModal}
        onKeyLeft={selectPreviousImage}
        onKeyRight={selectNextImage}
      >
        <SwipeEvents
          onSwipeLeft={selectNextImage}
          onSwipeRight={selectPreviousImage}
        >
          <ModalContent>
            {selectedImage && <Image file={selectedImage} />}
          </ModalContent>
        </SwipeEvents>
      </Modal>
    </>
  )
}

export default Gallery
