import React from 'react'
import theme from 'lib/styles/theme'

const SquareNine = ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <rect fill={fill} width={6} height={6} x={0} y={0} />
    <rect fill={fill} width={6} height={6} x={9} y={0} />
    <rect fill={fill} width={6} height={6} x={18} y={0} />
    <rect fill={fill} width={6} height={6} x={0} y={9} />
    <rect fill={fill} width={6} height={6} x={9} y={9} />
    <rect fill={fill} width={6} height={6} x={18} y={9} />
    <rect fill={fill} width={6} height={6} x={0} y={18} />
    <rect fill={fill} width={6} height={6} x={9} y={18} />
    <rect fill={fill} width={6} height={6} x={18} y={18} />
  </svg>
)

export default SquareNine
