import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'
import styled from 'styled-components'
import { rem, flex, swap, isnt, position, transparentize } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import CloseButton from 'components/close-button'

let SCROLL_LOCK_TIMEOUT

const { colors, speeds } = theme
const { white, vapor, tar, black, yellow } = colors

const Overlay = styled.div`
  display: none;

  ${media.medium`
    ${position('fixed', '-100%')}
    animation:
      ${swap('show', 'fadeIn', 'fadeOut')}
      ${speeds.gradual}ms forwards ease;
    background: ${transparentize(black, 0.15)};
    display: block;
    z-index: 98;

    ${isnt('show')`
      pointer-events: none;
      z-index: -1;
    `}
  `}
`

const ModalWrap = styled.div`
  ${position('fixed', 0)}
  animation:
    ${swap('show', 'modalInMobile', 'fadeOut')}
    ${speeds.gradual}ms forwards ease;
  background: ${white};
  z-index: 99;

  ${isnt('show')`
    pointer-events: none;
    z-index: -1;
  `}

  ${media.medium`
    ${position('fixed', '50%', 'auto', 'auto', '50%')}
    animation-name: ${swap('show', 'modalInDesktop', 'fadeOut')};
    box-shadow: 0 0 ${rem(48)} ${black};
    transform: translate(-50%, -50%);
    max-width: ${rem(800)};

    :after {
      ${position('absolute', 0)}
      box-shadow: 0 0 ${rem(1)} ${rem(1)} ${black};
      content: '';
      pointer-events: none;
      z-index: 3;
    }
  `}
`

const TitleBar = styled.div`
  ${flex('row', 'center', 'space-between')}
  ${position('absolute', 0, 0, 'auto')}
  background: ${black};
  height: ${rem(48)};
  z-index: 2;

  a {
    border-left: 0;

    :hover,
    :focus {
      background: ${tar};
      color: ${yellow};
    }
  }

  ${media.medium`
    box-shadow: 0 0 0 ${rem(1)} ${black};
  `}
`

const Body = styled.div`
  ${flex('column', 'center', 'flex-start')}
  background: ${vapor};
  height: calc(100vh - ${rem(48)});
  overflow-x: hidden;
  overflow-y: auto;
  position: relative;
  margin: ${rem(48)} ${rem(-1)} 0;

  ${media.medium`
    height: auto;
    max-height: calc(100vh - ${rem(48)});
  `}
`

const Modal = ({ children, show, title, onClose, onKeyLeft, onKeyRight }) => {
  const { body } = global.document || {}

  const handleClose = () => {
    if (onClose) onClose()
  }

  const handleKeyPress = event => {
    if (event.keyCode === 37 && onKeyLeft) onKeyLeft()
    if (event.keyCode === 39 && onKeyRight) onKeyRight()
  }

  useEffect(() => {
    if (show) {
      clearTimeout(SCROLL_LOCK_TIMEOUT)
      SCROLL_LOCK_TIMEOUT = setTimeout(() => {
        document.querySelector('body').style.overflow = 'hidden'
      }, speeds.gradual)
    } else {
      document.querySelector('body').style.overflow = 'initial'
    }
    global.document.addEventListener('keydown', handleKeyPress)
    return () => {
      clearTimeout(SCROLL_LOCK_TIMEOUT)
      global.document.removeEventListener('keydown', handleKeyPress)
    }
  }, [show, handleKeyPress])

  if (!body) return null
  return ReactDOM.createPortal(
    <>
      <Overlay show={show} onClick={handleClose} />
      <ModalWrap show={show}>
        <TitleBar>
          <h2>{title}</h2>
          <CloseButton onClick={handleClose} />
        </TitleBar>
        <Body>{children}</Body>
      </ModalWrap>
    </>,
    body,
  )
}

export default Modal
