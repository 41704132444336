import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

export default ({ file, alt = '', ...rest }) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                fluid(maxWidth: 832) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { edges } = data.images
      const image = edges.find(n => n.node.relativePath.includes(file))
      const { src } = image.node.childImageSharp.fluid

      return <img src={src} alt={alt} {...rest} />
    }}
  />
)
