import React from 'react'

const SwipeEvents = ({
  is = 'div',
  tolerance = 64,
  onSwipeUp,
  onSwipeDown,
  onSwipeLeft,
  onSwipeRight,
  onSwipeAny,
  children,
}) => {
  let initialX = 0
  let initialY = 0
  let currentX = 0
  let currentY = 0

  const onTouchStart = ({ touches }) => {
    initialX = touches[0].clientX
    initialY = touches[0].clientY
  }

  const onTouchMove = ({ touches }) => {
    currentX = touches[0].clientX
    currentY = touches[0].clientY
  }

  const onTouchEnd = () => {
    if (!currentX && !currentY) return

    const xDelta = Math.abs(initialX - currentX)
    const yDelta = Math.abs(initialY - currentY)

    if (xDelta > tolerance || yDelta > tolerance) {
      if (yDelta > xDelta) {
        if (currentY < initialY && onSwipeUp) {
          onSwipeUp()
        } else if (onSwipeUp) {
          onSwipeDown()
        }
      } else if (currentX < initialX && onSwipeLeft) {
        onSwipeLeft()
      } else if (onSwipeRight) {
        onSwipeRight()
      }
      if (onSwipeAny) onSwipeAny()
    }
    currentX = 0
    currentY = 0
    initialX = 0
    initialY = 0
  }

  return React.createElement(
    is,
    { role: 'presentation', onTouchStart, onTouchMove, onTouchEnd },
    children,
  )
}

export default SwipeEvents
