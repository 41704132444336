import React from 'react'
import theme from 'lib/styles/theme'

const Square = ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <rect fill={fill} width={24} height={24} x={0} y={0} />
  </svg>
)

export default Square
