import React, { useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'
import { rem, flex, is, position } from 'styled-tidy'
import theme from 'lib/styles/theme'
import media from 'lib/styles/media'
import stopEvent from 'lib/common/stop-event'
import Gallery, { GalleryWrap } from 'components/gallery'
import Layout from 'layouts/default'
import Title from 'components/title'
import Square from 'components/icons/square'
import SquareFour from 'components/icons/square-four'
import SquareNine from 'components/icons/square-nine'

const { colors, speeds } = theme
const { smoke, silver, ember, black } = colors

const Options = styled.div`
  ${flex('row', 'center', 'space-around')}
  border-bottom: ${rem(1)} solid ${smoke};
  height: ${rem(24)};
  margin: 0 0 ${rem(16)};
  padding: 0 0 ${rem(16)};
  width: 100%;

  ${media.small`
    ${position('absolute', 0, 16, 'auto', 'auto')}
    border-bottom: 0;
    justify-content: flex-end;
    transform: translateY(${rem(48)});

    svg {
      height: ${rem(16)};
      width: ${rem(16)};
    }
  `}
`

const Option = styled.a`
  ${flex('row', 'center', 'center')}
  display: block;
  flex-grow: 1;
  height: ${rem(24)};
  
  rect {
    fill: ${silver};
    transition: fill ${speeds.quick}ms ease;
  }

  :hover,
  :focus {
    rect {
      fill: ${ember};
    }
  }

  ${is('current')`
    &,
    :hover,
    :focus {
      rect {
        fill: ${black};
      }
    }
  `}

  ${media.small`
    flex-grow: 0;

    & + & {
      margin-left: ${rem(16)};
    }
  `}

  ${media.medium`
    :first-child {
      display: none;
    }
  `}
`

const GalleryPage = () => {
  const [columns, setColumns] = useState(3)

  const handleOptionClick = cols => event => {
    stopEvent(event)
    setColumns(cols)
  }

  const data = useStaticQuery(
    graphql`
      query {
        images: allFile(filter: { sourceInstanceName: { eq: "images" } }) {
          edges {
            node {
              name
              relativePath
              modifiedTime
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400, fit: COVER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    `,
  )
  const imageNodes = data.images.edges
    .filter(edge => edge.node.relativePath.match(/gallery\//i))
    .map(({ node }) => ({
      path: node.relativePath,
      date: new Date(node.modifiedTime),
      fluid: node.childImageSharp.fluid,
    }))
    .reverse()
    .sort((a, b) => (a.modifiedTime > b.modifiedTime ? -1 : 1))
    .sort(a => (a.path.match(/gallery\/new\//i) ? -1 : 1))

  return (
    <Layout path="/gallery">
      <GalleryWrap>
        <Title>Gallery</Title>
        <Options>
          <Option current={columns === 1} onClick={handleOptionClick(1)}>
            <Square />
          </Option>
          <Option current={columns === 2} onClick={handleOptionClick(2)}>
            <SquareFour />
          </Option>
          <Option current={columns === 3} onClick={handleOptionClick(3)}>
            <SquareNine />
          </Option>
        </Options>
        <Gallery columns={columns} imageNodes={imageNodes} />
      </GalleryWrap>
    </Layout>
  )
}

export default GalleryPage
