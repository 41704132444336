import React from 'react'
import theme from 'lib/styles/theme'

const SquareFour = ({ size = 24, fill = theme.colors.black, ...rest }) => (
  <svg
    {...rest}
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
  >
    <rect fill={fill} width={11} height={11} x={0} y={0} />
    <rect fill={fill} width={11} height={11} x={0} y={13} />
    <rect fill={fill} width={11} height={11} x={13} y={0} />
    <rect fill={fill} width={11} height={11} x={13} y={13} />
  </svg>
)

export default SquareFour
